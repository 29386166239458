import React, { Component } from "react";
import {
  Modal,
  Button,
  Space,
  Table,
  Avatar,
  Tag,
  Tooltip,
  Menu,
  Dropdown,
} from "antd";
import { fetchUtil } from "~/api/common";
import { LoginRole } from "~/api/auth";
import FieldInfo from "./FieldInfo";
import styles from "~/layout/main-layout/MainLayout.module.css";
import FySelect from "./FySelect";
import { format, addYears, getMonth } from "date-fns";
import getYear from "date-fns/fp/getYear";
import { subYears } from "date-fns/esm";
import { logRoles } from "@testing-library/react";
import { Helmet } from "react-helmet";
import { titles } from "~/contants/titles";
import { registerEvent } from "~/analytics";
import * as ga from "~/contants/gaConstants";
import { clamp } from "lodash";
interface Props {
  visible: boolean;
  onVisibleCHange: (visible: boolean) => void;
  userInfo: any;
  activeRole: number;
  handleCompanyChange: (id: any) => void;
  handleAddCliantPopup: (visible: boolean) => void;
  handleSwitch: (comId: any, role: any) => void;
  updateList?: boolean;
  handleRestoreState?: () => void;
  handleUpdateCompanyList?: (val: boolean) => void;
}

interface State {
  tableData: any;
  tableLoading: boolean;
  selectedFy: string | null;
  totalComapnyCount: any;
  addedClients: any;
}
function getLogoURL(taxName: string) {
  return `/tax-logo/${taxName.toLowerCase()}.png`;
}

const user = localStorage.getItem("imt__user");
const userData = JSON.parse(user!);

export default class CompanyListModal extends Component<Props, State> {
  state: State = {
    tableData: [],
    tableLoading: false,
    selectedFy: "",
    totalComapnyCount: 0,
    addedClients: 0,
  };

  componentDidMount() {
    const year = new Date().getFullYear();
    const current = new Date();
    const eligible =
      current.getTime() <= new Date(`${year}-04-01`).getTime() &&
      current.getTime() > new Date(`${year - 1}-04-01`).getTime();
    this.setState(
      {
        selectedFy: eligible
          ? `${getYear(subYears(current, 1))}-${getYear(current)}`
          : `${getYear(current)}-${getYear(addYears(current, 1))}`,
      },
      () => {
        this.getContent();
      }
    );
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    // console.log(prevProps.updateList,this.props.updateList)
    if (prevProps.updateList !== this.props.updateList) {
      // if (this.props.updateList) {
      this.getContent().then(() => this.props.handleUpdateCompanyList!(false));
      // }
    }
  }

  async getContent() {
    this.setState({
      tableLoading: true,
    });
    // console.log(
    //   Number(this.state.selectedFy!.slice(0, 4)),
    //   Number(this.state.selectedFy!.slice(5))
    // );
    const { ok, json, message } = await fetchUtil("POST", `/get_company_list`, {
      fy: this.state.selectedFy,
    });
    if (ok) {
      this.setState({
        tableData: json[0].companies,
        tableLoading: false,
        totalComapnyCount: json[0].clients,
        addedClients: json[0].added_clients,
      });
    } else {
      this.setState({
        tableLoading: false,
      });
    }
  }

  handleSwitch = (id: number, cheacked: boolean) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.companyListModal19
    );

    if (cheacked) {
      this.props.handleCompanyChange(id);
    }
  };

  handleFyChange = (value: any) => {
    this.setState(
      {
        selectedFy: value,
      },
      () => {
        this.getContent();
      }
    );
  };

  showTagData = (f: any) => {
    return (
      <>
        <Helmet>
          <title>{titles.CompanyList}</title>
        </Helmet>
        <Tooltip placement="leftTop" title={`${f.overdue} overdue`}>
          <Tag
            id={f.overdue !== 0 ? styles.overdueTag : styles.TagForZero}
            key={f.name}
          >
            <b style={{ color: "black" }}> {f.overdue}</b>
          </Tag>
        </Tooltip>
        <Tooltip placement="rightTop" title={`${f.pending} pending`}>
          <Tag
            key={f.name}
            id={f.pending !== 0 ? styles.pendingTag : styles.TagForZero}
          >
            <b style={{ color: "black", textAlign: "center" }}>{f.pending}</b>
          </Tag>
        </Tooltip>
      </>
    );
  };

  openAddClint = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.companyListModal20
    );

    this.props.handleAddCliantPopup(true);
  };

  render() {
    const { visible } = this.props;
    const columns: Array<any> = [
      {
        title: "Company name",
        dataIndex: "name",
        key: "name",
        ellipsis: true,
        width: 300,
        sorter: (a: any, b: any) => a.name.length - b.name.length,
        render: (name: string, row: any) => {
          const isAdmin: boolean = row.roles.find((el: any) => {
            // return(el===LoginRole.ADMIN?true:false)
            if (el === LoginRole.ADMIN) {
              return true;
            } else {
              return false;
            }
          });
          // console.log(isAdmin);
          return (
            <>
              <Tooltip title={name}>
                <div>
                  <p
                    style={{
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    {name}
                  </p>
                </div>
              </Tooltip>

              <div
                style={{
                  marginTop: "14px",
                  marginLeft: "-4px",
                  display: "flex",
                }}
              >
                {row.gstin ? (
                  <FieldInfo text={row.gstin} />
                ) : (
                  <FieldInfo text="GST unregister" />
                )}

                {isAdmin &&
                this.props.userInfo.primary_associated_company_id !== row.id ? (
                  <Tag
                    color="green"
                    style={{
                      marginTop: "-14px",
                      marginLeft: "10px",
                      fontSize: "9px",
                      padding: "0px 4px",
                      lineHeight: "18px",
                    }}
                  >
                    CLIENT
                  </Tag>
                ) : this.props.userInfo.primary_associated_company_id ===
                  row.id ? (
                  <Tag
                    color="blue"
                    style={{
                      marginTop: "-14px",
                      marginLeft: "10px",
                      fontSize: "9px",
                      color: "#2F80ED",
                      padding: "0px 4px",
                      lineHeight: "18px",
                    }}
                  >
                    PRIMARY
                  </Tag>
                ) : (
                  <Tag
                    // color="green"
                    style={{
                      marginTop: "-14px",
                      marginLeft: "10px",
                      fontSize: "9px",
                      // color: "green",
                      padding: "0px 4px",
                      lineHeight: "18px",
                    }}
                  >
                    LIMITED ACCESS
                  </Tag>
                )}
              </div>
            </>
          );
        },
      },
      {
        title: <Avatar src={getLogoURL("gst")} />,
        dataIndex: "compliances",
        key: "id",
        align: "center",
        sorter: (a: any, b: any) => {
          const x = a.compliances.find((el: any) => el.name === "GST");
          const y = b.compliances.find((el: any) => el.name === "GST");

          return (
            //  (a.compliances[0].overdue+a.compliances[0].pending)-
            //  (b.compliances[0].overdue+b.compliances[0].pending)
            x?.overdue + x?.pending - (y?.overdue + y?.pending)
          );
        },

        render: (compliances: any, row: any) => {
          const isExist = compliances.find((t: any) => t.name === "GST");
          return isExist ? (
            <>
              {compliances.map((f: any) => {
                return f.name === "GST" ? <>{this.showTagData(f)}</> : null;
              })}
            </>
          ) : (
            "—"
          );
        },
      },
      {
        title: <Avatar src={getLogoURL("pf")} />,
        dataIndex: "compliances",
        key: "a",
        align: "center",
        sorter: (a: any, b: any) => {
          const x = a.compliances.find((el: any) => el.name === "PF");
          const y = b.compliances.find((el: any) => el.name === "PF");

          return (
            //  (a.compliances[0].overdue+a.compliances[0].pending)-
            //  (b.compliances[0].overdue+b.compliances[0].pending)
            x?.overdue + x?.pending - (y?.overdue + y?.pending)
          );
        },
        render: (compliances: any, row: any) => {
          const isExist = compliances.find((t: any) => t.name === "PF");
          return isExist ? (
            <>
              {compliances.map((f: any) => {
                return f.name === "PF" ? <>{this.showTagData(f)}</> : null;
              })}
            </>
          ) : (
            "—"
          );
        },
      },
      {
        title: <Avatar src={getLogoURL("esi")} />,
        dataIndex: "compliances",
        key: "b",
        align: "center",
        sorter: (a: any, b: any) => {
          const x = a.compliances.find((el: any) => el.name === "ESI");
          const y = b.compliances.find((el: any) => el.name === "ESI");

          return (
            //  (a.compliances[0].overdue+a.compliances[0].pending)-
            //  (b.compliances[0].overdue+b.compliances[0].pending)
            x?.overdue + x?.pending - (y?.overdue + y?.pending)
          );
        },
        render: (compliances: any, row: any) => {
          const isExist = compliances.find((t: any) => t.name === "ESI");
          return isExist ? (
            <>
              {compliances.map((f: any) => {
                return f.name === "ESI" ? <>{this.showTagData(f)}</> : null;
              })}
            </>
          ) : (
            "—"
          );
        },
      },
      {
        title: <Avatar src={getLogoURL("ptax")} />,
        dataIndex: "compliances",
        key: "c",
        align: "center",
        sorter: (a: any, b: any) => {
          const x = a.compliances.find((el: any) => el.name === "PTAX");
          const y = b.compliances.find((el: any) => el.name === "PTAX");

          return (
            //  (a.compliances[0].overdue+a.compliances[0].pending)-
            //  (b.compliances[0].overdue+b.compliances[0].pending)
            x?.overdue + x?.pending - (y?.overdue + y?.pending)
          );
        },
        render: (compliances: any, row: any) => {
          const isExist = compliances.find((t: any) => t.name === "PTAX");
          return isExist ? (
            <>
              {compliances.map((f: any) => {
                return f.name === "PTAX" ? <>{this.showTagData(f)}</> : <></>;
              })}
            </>
          ) : (
            "—"
          );
        },
      },
      {
        title: <Avatar src={getLogoURL("tds")} />,
        dataIndex: "compliances",
        key: "d",
        align: "center",
        sorter: (a: any, b: any) => {
          const x = a.compliances.find((el: any) => el.name === "TDS");
          const y = b.compliances.find((el: any) => el.name === "TDS");

          return (
            //  (a.compliances[0].overdue+a.compliances[0].pending)-
            //  (b.compliances[0].overdue+b.compliances[0].pending)
            x?.overdue + x?.pending - (y?.overdue + y?.pending)
          );
        },
        render: (compliances: any, row: any) => {
          const isExist = compliances.find((t: any) => t.name === "TDS");
          return isExist ? (
            <>
              {compliances.map((f: any) => {
                return f.name === "TDS" ? <>{this.showTagData(f)}</> : null;
              })}
            </>
          ) : (
            "—"
          );
        },
      },
      {
        title: <Avatar src={getLogoURL("mca")} />,
        dataIndex: "compliances",
        key: "e",
        align: "center",
        sorter: (a: any, b: any) => {
          const x = a.compliances.find((el: any) => el.name === "MCA");
          const y = b.compliances.find((el: any) => el.name === "MCA");

          return (
            //  (a.compliances[0].overdue+a.compliances[0].pending)-
            //  (b.compliances[0].overdue+b.compliances[0].pending)
            x?.overdue + x?.pending - (y?.overdue + y?.pending)
          );
        },
        render: (compliances: any, row: any) => {
          const isExist = compliances.find((t: any) => t.name === "MCA");
          return isExist ? (
            <>
              {compliances.map((f: any) => {
                return f.name === "MCA" ? <>{this.showTagData(f)}</> : null;
              })}
            </>
          ) : (
            "—"
          );
        },
      },

      {
        title: "",
        dataIndex: "id",
        key: "f",
        align: "center",
        render: (id: any, record: any) => {
          const cheackCompany = this.props.userInfo.company_details.find(
            (el: any) => el.name === this.props.userInfo.company_name
          );
          const menu = (
            <Menu>
              {record.roles.map((role: any) => {
                return (record.name ? record.name : undefined) ===
                  (cheackCompany ? cheackCompany.name : undefined) &&
                  role === this.props.userInfo.activeRole ? (
                  <></>
                ) : (
                  <Menu.Item
                    key="0"
                    onClick={() => this.props.handleSwitch(record.id, role)}
                    data-id={role}
                  >
                    {role === LoginRole.ADMIN
                      ? "Admin"
                      : role === LoginRole.SUPERuSER
                      ? "Superuser"
                      : role === LoginRole.CA
                      ? "CA"
                      : role === LoginRole.EMPLOYEE
                      ? "Employee"
                      : role === LoginRole.DELIGATE
                      ? "Deligate user"
                      : null}
                  </Menu.Item>
                );
              })}
            </Menu>
          );
          return (
            <>
              {record.name ===
                (cheackCompany
                  ? cheackCompany.name
                  : this.props.userInfo.company_name) &&
              record.roles.length === 1 ? (
                <span
                  style={{
                    fontSize: "12px",
                    fontStyle: "italic",
                    fontWeight: 700,
                    color: "grey",
                  }}
                >
                  Current
                </span>
              ) : (
                <div>
                  <Space align="center">
                    <Dropdown
                      overlay={menu}
                      trigger={["click"]}
                      placement="bottomLeft"
                    >
                      <span
                        className="ant-dropdown-link"
                        // tslint:disable-next-line: jsx-no-lambda
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Button type="link">switch</Button>
                      </span>
                    </Dropdown>
                  </Space>
                </div>
              )}
            </>
          );
        },
      },
    ];

    return (
      <>
        <Modal
          maskClosable={false}
          visible={visible}
          footer={null}
          // onOk={() => this.props.onVisibleCHange(false)}
          onCancel={() => this.props.onVisibleCHange(false)}
          width={"clamp(0px,100vw,1000px)"}
          style={{
            overflow: "auto",
            minHeight: "100px",
            boxShadow: "5px 5px 15px #d9d7d7",
            background: "white",
            padding: "0px",
            borderRadius: "12px",
          }}
          destroyOnClose
          centered
        >
          <div style={{ height: "100%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                marginTop: "15px",
              }}
            >
              <div>
                <h3
                  style={{
                    fontSize: "26px",
                    color: "#a2a6a3",
                  }}
                >
                  Company list
                </h3>
                <div
                  style={{
                    marginTop: "var(--margin-sm)",
                    fontSize: "14px",
                    color: "#000",
                    fontWeight: 400,
                    // color:'blue'
                  }}
                >
                  {/* Company count - ({this.props.userInfo.client_companies})
                   */}
                  {/* {userData.activeRole !== LoginRole.EMPLOYEE && */}
                  {this.state.addedClients >= 0 &&
                  this.props.userInfo.primary_associated_company_id !== null ? (
                    <FieldInfo
                      text={`${this.state.addedClients}/${
                        this.state.totalComapnyCount
                      } added. ${
                        this.state.totalComapnyCount - this.state.addedClients
                      } more clients can be added`}
                    />
                  ) : null}
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                  gap: "5",
                  margin: "10px 5px",
                }}
              >
                <span>
                  <FySelect
                    selectedFy={this.state.selectedFy}
                    onFyChange={this.handleFyChange}
                    isCompanyList={true}
                  />
                </span>
                {/* {this.props.activeRole === LoginRole.EMPLOYEE ? (
                  <></>
                ) : ( */}
                <span style={{ marginLeft: "10px" }}>
                  <Button
                    type="primary"
                    size="small"
                    onClick={this.openAddClint}
                    disabled={
                      userData.added_clients >= userData.client_no ||
                      (userData.activeRole === LoginRole.CA &&
                        !userData.plan_purchased) ||
                      !userData.plan_purchased ||
                      this.props.userInfo.primary_associated_company_id === null
                    }
                  >
                    + Add client
                  </Button>
                </span>
                {/* )} */}
              </div>
            </div>
            <div style={{ width: "100%", overflow: "auto", height: "300px" }}>
              <div style={{ width: "100%", minWidth: "800px" }}>
                <Table
                  rowKey="id"
                  columns={columns}
                  style={{ width: "100%" }}
                  // dataSource={this.props.userInfo.company_details}
                  dataSource={this.state.tableData}
                  loading={this.state.tableLoading}
                  size={"small"}
                  pagination={false}
                />
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
