import React, { Component } from "react";
import { notification, Table, Select, Button, Modal, Input } from "antd";
import { connect, ConnectedProps } from "react-redux";
import { LoginRole } from "~/api/auth";
import { fetchUtil } from "~/api/common";
import { AuthState } from "~/app/MainApp/store";
import { roundNumber } from "~/lib";
// import SalaryEmployee from "./SalaryEmployee";
import Currency from "~/component/Currency";
import ReadOnlyableSelect from "~/component/field-input/ReadOnlyableSelect";
import classes from "../../payroll/SalaryTemplate.module.css";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";

const mapStateToProps = (
  state: AuthState,
  ownProps: { employeeID: number }
) => ({
  activeRole: state.mainAppSlice.user.activeRole,
  employeeID: ownProps.employeeID,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
interface Props extends PropsFromRedux {
  employeeID: number;
  isTabUpdated: boolean;
  changeTabUpdate: () => void;
  currentTab: string;
  active: boolean;
}

interface State {
  tableLoading: boolean;
  visible: boolean;
  is_disable: boolean;
  isDirty: boolean;
  employee: any;
  actualsByShortName: any;
  actualsByCategoryId: any;
  isSalaryAvailable: boolean;
  isEditing: boolean;
  activeBtn: boolean;
  tableData: Array<any>;
  categoriesList: Array<any>;
  categoriesById: any;
  templateList: Array<any>;
  salaryBreakUpObject: any;
  activeTemplateId: any;
  employeeActiveCategories: Array<any>;
}

const RELATIVE_ABSOLUTE_PICKLIST = [
  {
    id: "actual",
    value: "On actual",
  },
  {
    id: "percentage",
    value: "Percentage",
  },
];

class SalaryDetailsPane extends Component<Props, State> {
  state: State = {
    tableLoading: true,
    visible: false,
    is_disable: true,
    isDirty: false,
    employee: {},
    actualsByShortName: {},
    actualsByCategoryId: {},
    isSalaryAvailable: false,
    isEditing: false,
    tableData: [],
    templateList: [],
    categoriesList: [],
    categoriesById: {},
    salaryBreakUpObject: {},
    activeBtn: true,
    activeTemplateId: null,
    employeeActiveCategories: [],
  };

  componentDidMount() {
    this.getSalaryDetails();
    if (
      this.props.activeRole === LoginRole.ADMIN ||
      this.props.activeRole === LoginRole.SUPERuSER
    ) {
      this.setState({ is_disable: false });
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: State) {
    if (this.props.currentTab === "salary" && !this.props.isTabUpdated) {
      this.getSalaryDetails();
      if (
        this.props.activeRole === LoginRole.ADMIN ||
        this.props.activeRole === LoginRole.SUPERuSER
      ) {
        this.setState({ is_disable: false });
      }
      this.props.changeTabUpdate();
    }
    if (prevProps.employeeID !== this.props.employeeID) {
      this.getSalaryDetails();
    }
    if (prevState.tableData !== this.state.tableData) {
      this.setState({
        salaryBreakUpObject: this.getSalaryBreakupObject(this.state.tableData),
      });
    }
  }

  async getSalaryDetails() {
    const { ok, json, message } = await fetchUtil(
      "POST",
      `/get_salary_details`,
      {
        emp_id: this.props.employeeID,
      }
    );

    if (ok) {
      const {
        employee: [{ employeeData }],
        template_details,
      } = json[0];

      const {
        company_employee_id,
        designation,
        designation_id,
        emp_email,
        employee_id,
        template_id,
      } = employeeData;
      const employee = {
        company_employee_id,
        designation,
        designation_id,
        emp_email,
        employee_id,
        template_id,
      };

      const actualsCategoryIdByShortName = {} as any;
      template_details.forEach((it: any) => {
        if (it.field_type === "actual") {
          actualsCategoryIdByShortName[it.short_name] = it.category_id;
        }
      });

      const actualsByShortName = {} as any;
      const actualsByCategoryId = {} as any;
      const categoriesList = Object.keys(employeeData.salary_details);
      Object.keys(employeeData.salary_details).forEach((shortName: any) => {
        if (shortName in actualsCategoryIdByShortName) {
          const salary = employeeData.salary_details[shortName].val;
          actualsByShortName[shortName] = salary;
          actualsByCategoryId[actualsCategoryIdByShortName[shortName]] = salary;
        }
      });

      this.setState({
        employee,
        actualsByShortName,
        actualsByCategoryId,
        isSalaryAvailable: template_details.length > 0,
        activeTemplateId: employee.template_id,
        employeeActiveCategories: categoriesList,
      });
      await this.getCategoryList();
      await this.loadTemplateData();
    } else {
      notification.error({ message });
    }
  }

  async getCategoryList() {
    const { ok, json, message } = await fetchUtil(
      "POST",
      `/get_salary_categories`
    );

    if (ok) {
      const categoriesById: any = {};
      json.forEach((it) => {
        categoriesById[it.id] = it.display_name;
      });

      this.setState({
        categoriesList: json,
        categoriesById,
      });
    } else {
      notification.error({ message });
    }
  }

  async loadTemplateData() {
    const { ok, json, message } = await fetchUtil(
      "POST",
      "/get_salary_templates"
    );
    if (!ok) {
      console.warn(message, json);
      notification.error({ message: "Failed to load templates." });
    } else {
      try {
        json.sort((a, b) => a.id - b.id);
        const detailsList = await Promise.all(
          json.map(async (it: any) => {
            const { ok: dOk, json: dJson, message: dMessage } = await fetchUtil(
              "POST",
              "/get_salary_templates_fields",
              {
                template_id: it.id,
              }
            );
            if (!dOk) {
              throw new Error(
                `Failed to load details for template: ${it.id} (${it.template_name}). Message: ${dMessage}`
              );
            }
            dJson.forEach((dIt) => {
              dIt.reference_id = +dIt.reference_id;
            });
            return dJson;
          })
        );
        const combinedTemplateList = json.map((tIt, index) => {
          const dIt = detailsList[index];
          return {
            id: tIt.id,
            name: tIt.template_name,
            tableData: dIt,
          };
        });
        const { activeTemplateId } = this.state;
        const templateList = activeTemplateId
          ? combinedTemplateList.find((val) => val.id === activeTemplateId)
              ?.tableData
          : combinedTemplateList[0].tableData;
        const tableData = this.getTableDataFromActuals(
          templateList,
          this.state.actualsByShortName
        );

        this.setState({
          tableData,
          templateList: combinedTemplateList,
          activeTemplateId: activeTemplateId ?? combinedTemplateList[0].id,
        });
      } catch (error) {
        console.warn(error);
        notification.error({ message: "Failed to load template details." });
      }
    }
  }

  handleSalarySave = async (templateId: number, salaryObject: any) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.salaryDetailsPane52
    );

    const object = {
      is_active: true,
      template_id: templateId,
      emp_id: this.state.employee.employee_id,
      ...salaryObject,
    };
    const { ok, json, message } = await fetchUtil(
      "POST",
      "/save_employee_salary_details",
      object
    );
    if (!ok) {
      notification.warn({
        message: "Failed to save revised salary",
        description: message,
      });
    } else {
      notification.success({
        message: "Successfully saved revised salary",
        description: message,
      });
      this.getSalaryDetails();
    }
    return ok;
  };

  handleCancelBtn = async () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.salaryDetailsPane53
    );

    // TODO: Reset the value to initial if we are editing existing template
    // else if we discards a new template, select & view the "Standard template"
    await this.getSalaryDetails();
    this.setState({
      isEditing: false,
    });
  };

  handleEditBtn = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.salaryDetailsPane54
    );

    this.setState({
      isEditing: true,
    });
  };

  handleSaveBtn = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.salaryDetailsPane55
    );

    Modal.confirm({
      title: "Confirm save",
      content: <div>Do you want to save the revised salary?</div>,
      onCancel: this.handleCancelBtn,
      onOk: async () => {
        const success = await this.handleSalarySave(
          this.state.activeTemplateId!,
          this.getSalaryBreakupObject(this.state.tableData)
        );
        if (success) {
          this.setState({
            isEditing: false,
          });
        }
      },
    });
  };

  handleReviseSalaryBtn = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.salaryDetailsPane56
    );

    this.setState({
      isEditing: true,
    });
  };

  handleTemplateChange = (newTemplateId: number) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.salaryDetailsPane57
    );
    this.setState(
      {
        employeeActiveCategories: this.state.templateList
          .find((it) => it.id === newTemplateId)
          .tableData.map((val: any) => val.category_name),
      },
      () => {
        const tableData = this.getTableDataFromActuals(
          this.state.templateList.find((it) => it.id === newTemplateId)
            .tableData,
          this.state.actualsByShortName
        );
        this.setState({
          activeTemplateId: newTemplateId,
          tableData,
        });
      }
    );
  };

  handleCellChange = (
    fieldName: string,
    index: number,
    changedValue: string | number
  ) => {
    const changedRow = this.state.tableData[index];
    const updatedRow = {
      ...changedRow,
      [fieldName]: changedValue,
    };

    this.setState({
      tableData: [
        ...this.state.tableData.slice(0, index),
        updatedRow,
        ...this.state.tableData.slice(index + 1),
      ],
    });
  };

  getTableDataFromActuals(tableData: any, actualsByShortName: any) {
    const { employeeActiveCategories, actualsByCategoryId } = this.state;

    let newTableData = tableData.filter((row: any) => {
      if (employeeActiveCategories.length > 0) {
        return employeeActiveCategories.indexOf(row.category_name) !== -1;
      } else return true;
    });

    newTableData = newTableData.map((row: any) => {
      if (
        employeeActiveCategories.length > 0
          ? employeeActiveCategories.indexOf(row.category_name) !== -1
          : true
      ) {
        const isOnActual = row.field_type === "actual";
        if (!isOnActual) {
          return row;
        } else {
          return {
            ...row,
            amount: actualsByCategoryId[row.category_id] ?? 0,
          };
        }
      }
      return null;
    });

    return newTableData;
  }

  getSalaryBreakupObject(tableData: Array<any>) {
    const result = {} as any;
    let ctc = 0;
    tableData.forEach((row: any) => {
      const isOnActual = row.field_type === "actual";
      let amount = row.amount;
      if (!isOnActual) {
        const referredRow = tableData.find(
          (it: any) => it.category_id === row.reference_id
        );
        if (referredRow) {
          const refAmount = +referredRow.amount;
          amount = Number.isFinite(refAmount)
            ? roundNumber((refAmount * +amount) / 100)
            : 0;
        } else {
          amount = 0;
        }
      }
      const categoryRow = this.state.categoriesList.find(
        (it) => it.id === row.category_id
      );
      amount = amount ? +amount : 0;
      result[categoryRow?.short_name] = amount;
      if (categoryRow?.short_name === "other_recurring_deduction") {
        ctc -= amount;
      } else {
        ctc += amount;
      }
    });
    result.ctc = ctc;
    return result;
  }

  getColumnDef() {
    const isEditing = this.state.isEditing;

    const columnDef: any = [
      {
        title: "Category",
        dataIndex: "category_id",

        render: (value: number, row: any, index: number) => {
          const selectedCategory = this.state.categoriesList.find(
            (it) => it.id === value
          );
          return <div>{selectedCategory?.display_name}</div>;
        },
      },

      {
        title: "Relative/Absolute",
        dataIndex: "field_type",

        render: (value: any, row: any, index: number) => {
          const selectedFieldType = RELATIVE_ABSOLUTE_PICKLIST.find(
            (it) => it.id === value
          );

          return <div>{selectedFieldType?.value}</div>;
        },
      },

      {
        title: "Reference",
        dataIndex: "reference_id",

        render: (value: any, row: any, index: number) => {
          const selectedCategory = this.state.categoriesList.find(
            (it) => it.id === value
          );
          return <div>{selectedCategory?.display_name ?? "N/A"}</div>;
        },
      },

      {
        title: "Amount/Percentage",
        dataIndex: "amount",
        align: "right",

        render: (value: any, row: any, index: number) => {
          let inputValue = value;
          const isOnActual = row.field_type === "actual";

          if (!isOnActual) {
            const referredRow = this.state.tableData.find(
              (it: any) => it.category_id === row.reference_id
            );
            const refAmount = +referredRow.amount;
            inputValue = Number.isFinite(refAmount)
              ? roundNumber((refAmount * +inputValue) / 100)
              : 0;
          }

          if (isOnActual && isEditing) {
            return (
              <Input
                prefix={isOnActual ? undefined : `${value}% = `}
                readOnly={!isOnActual || !isEditing}
                value={inputValue}
                // tslint:disable-next-line: jsx-no-lambda
                onChange={(event: any) => {
                  this.handleCellChange("amount", index, event.target.value);
                }}
              />
            );
          } else {
            return (
              <div>
                {!isOnActual && (
                  <span style={{ color: "var(--primary-color)" }}>
                    ({value}%)
                  </span>
                )}{" "}
                <Currency currencySymbol={"₹"}>{inputValue}</Currency>
              </div>
            );
          }
        },
      },
    ];

    return columnDef;
  }

  render() {
    const { activeRole } = this.props;
    const { isSalaryAvailable, isEditing } = this.state;
    const columnDef = this.getColumnDef();
    const templateItem = this.state.templateList.find(
      (it: any) => it.id === this.state.activeTemplateId
    );
    const isStandardTemplate = templateItem?.company_id === null;
    const isEditEnabled = !isStandardTemplate;

    return (
      <div>
        <div className={classes.tableWrapper}>
          {activeRole === LoginRole.EMPLOYEE || activeRole === LoginRole.CA ? (
            !isSalaryAvailable ? (
              <b>
                {activeRole === LoginRole.EMPLOYEE ? "Your salary" : "Salary"}{" "}
                structure is yet to be created.
              </b>
            ) : null
          ) : null}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "90vw",
            }}
          >
            {isSalaryAvailable || isEditing ? (
              <div className={classes.dropdownWrap}>
                <ReadOnlyableSelect
                  readonly={!isEditing}
                  value={this.state.activeTemplateId}
                  onChange={this.handleTemplateChange}
                >
                  {this.state.templateList.map((it) => (
                    <Select.Option key={it.id} value={it.id}>
                      {it.name}
                    </Select.Option>
                  ))}
                </ReadOnlyableSelect>
              </div>
            ) : null}
            {!isEditing &&
            activeRole !== LoginRole.CA &&
            activeRole !== LoginRole.EMPLOYEE ? (
              this.props.active ? (
                <Button type="primary" onClick={this.handleReviseSalaryBtn}>
                  {isSalaryAvailable ? "Revise Salary" : "Create Salary"}
                </Button>
              ) : null
            ) : (
              <div>
                <span>
                  {isEditEnabled && isEditing && (
                    <Button type="primary" onClick={this.handleSaveBtn}>
                      Save
                    </Button>
                  )}
                </span>
                <span style={{ marginLeft: "10px" }}>
                  {isEditEnabled && isEditing && (
                    <Button type="dashed" onClick={this.handleCancelBtn}>
                      Cancel
                    </Button>
                  )}
                </span>
              </div>
            )}
          </div>
          <div style={{ width: "90vw", overflow: "auto" }}>
            {isSalaryAvailable || isEditing ? (
              <Table
                rowKey="id"
                // className={classes.table}
                style={{ width: "60%", minWidth: "800px" }}
                columns={columnDef}
                dataSource={this.state.tableData}
                pagination={false}
                // tslint:disable-next-line: jsx-no-lambda
                footer={() => {
                  return (
                    <div className={classes.footerWrap}>
                      <div className={classes.footer}>
                        <div className={classes.footerRow}>
                          <span className={classes.footerLabel}>
                            Total Amount
                          </span>
                          <Currency
                            className={classes.footerAmount}
                            currencySymbol={"₹"}
                          >
                            {this.state.salaryBreakUpObject.ctc}
                          </Currency>
                        </div>
                      </div>
                    </div>
                  );
                }}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
export default connector(SalaryDetailsPane);
